
<template>
  <b-row>
    <b-col class="nopadding" cols="12" xl="3">
      <b-card>
        <div slot="header">
          <strong># {{$t('common.new_branch')}}</strong>
        </div>
        <div class="text-center">
          <img v-if="branch.logo" :src="computed_logo(branch.logo)"
          class="avatar img-circle img-thumbnail" alt="avatar">
          <img v-else src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
          class="avatar img-circle img-thumbnail" alt="avatar">
          <h6>{{$t('common.choose_another_image')}}</h6>
          <input type="file" class="text-center center-block file-upload"
          @change="getImage" id="fileControl">
        </div>
        <br>
        <div class="row">
          <div class="col bg-secondary form-fixer">
            <b-form-group>
              <label for="txt_name">{{$t('common.name')}}</label>
              <div v-if="$v.branch.name.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.branch.name.required">{{$t('common.name_required')}}</div>
                  <div class="text-danger" v-else-if="!$v.branch.name.isUnique">{{$t('common.name_existing')}}</div>
              </div>
              <b-form-input type="text" id="txt_name" :placeholder="$t('common.placeholder_name')"
              v-model="branch.name" maxlength="50" ref="txt_name" size="sm"
              v-on:input="$v.branch.name.$touch"></b-form-input>
            </b-form-group>
            <b-form-group v-if="is_super">
              <label for="inputCompany">{{$t('common.select_company')}}</label>
              <div v-if="$v.branch.company_id.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.branch.company_id.required">{{$t('common.company_required')}}</div>
              </div>
              <v-select :options="ds_company_ddl"
                :selected-option="branch.company_id"
                v-on:input="$v.branch.company_id.$touch"
                v-model="branch.company_id" @select="onSelect"
                :placeholder="$t('common.placeholder_company')">
              </v-select>
            </b-form-group>
            <b-form-group>
              <label for="txt_branch_code">{{$t('common.code')}}</label>
              <div v-if="$v.branch.code.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.branch.code.required">{{$t('common.code_required')}}</div>
                  <div class="text-danger" v-else-if="!$v.branch.code.isUnique">{{$t('common.code_existing')}}</div>
              </div>
              <b-form-input type="text" id="txt_branch_code"
              v-model="branch.code" maxlength="25"
              v-on:input="$v.branch.code.$touch" size="sm"
              :placeholder="$t('common.placeholder_code')"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="txt_branch_phone">{{$t('common.phone')}}</label>
              <div v-if="$v.branch.phone.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.branch.phone.required">{{$t('common.phone_required')}}</div>
                  <div class="text-danger" v-else-if="!$v.branch.phone.numeric">{{$t('common.phone_number_only')}}</div>
                  <div class="text-danger" v-else-if="!$v.branch.phone.minLength">{{$t('common.phone_ten_number')}}</div>
                  <div class="text-danger" v-else-if="!$v.branch.phone.isUnique">{{$t('common.phone_existing')}}</div>
              </div>
              <b-form-input type="text" id="txt_branch_phone"
              v-model="branch.phone" maxlength="20"
              v-on:input="$v.branch.phone.$touch" size="sm"
              :placeholder="this.$t('common.placeholder_phone')"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="txtAddress">{{$t('common.address')}}</label>
              <b-form-textarea id="txtAddress" size="sm"
              v-model="branch.address" maxlength="200"
              :placeholder="this.$t('common.placeholder_address')"></b-form-textarea>
            </b-form-group>
          </div>
        </div>
        <div class="row pull-right mt-2">
          <b-button variant="outline-success" size="sm" class="mr-1" @click.prevent="clear()">
            <i class="fa fa-refresh"></i> {{$t('common.button.refresh')}}</b-button>
          <b-button variant="outline-primary" size="sm" @click.prevent="submit()"><span class="icon is-small">
              <i class="fa fa-check"></i> {{$t('common.button.save')}}</span></b-button>
        </div>
      </b-card>
    </b-col>
    <b-col class="nopadding" cols="12" xl="9">
      <transition name="slide">
      <b-card>
        <div slot="header"><strong><i class="fa fa-list" aria-hidden="true"></i> {{$t('common.branch_list')}}</strong></div>
        <b-form-group label-for="table-style-variant" label-cols-lg="2">
          <div class="input-group">
            <input type="text" class="form-control" :placeholder="$t('common.search_text')"
            v-model="filter" maxlenght="50" @keyup.enter="apply_filter">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" @click="apply_filter"
                type="button"><i class="fa fa-search" aria-hidden="true"></i> {{$t('common.search')}}</button>
            </div>
          </div>
        </b-form-group>
        <b-table striped hover responsive :tbody-tr-class="rowDeleted"
        :items="filteredAndSortedData" :fields="fields" :current-page="page"
        :per-page="0" @row-clicked="rowClicked">
          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <input v-model="filteredAndSortedData[field.key]" 
              v-if="field.key && field.label" :placeholder="field.label">
            </td>
          </template>
          <template slot="name" slot-scope="data">
            <img v-if="data.item.logo" :src="computed_logo(data.item.logo)"
            class="avatar img-circle img-thumbnail" alt="avatar">
            <img v-else src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
            class="avatar img-circle img-thumbnail" alt="avatar">
            <br/>
            <div>{{data.item.name}}</div>
          </template>
          <template slot="branch" slot-scope="data">
            <label v-if="data.item.branch">{{data.item.branch.name}}</label>
            <label v-else>{{data.item.company.name}}</label>
          </template>
          <template slot="updated_at" slot-scope="data">
            <label v-if="data.item.updated_at">{{VnDateTimeFormat(data.item.updated_at)}}</label>
            <label v-else></label>
          </template>
          <template slot="created_at" slot-scope="data">
            <label>{{VnDateTimeFormat(data.item.created_at)}}</label>
          </template>
          <template slot="status" slot-scope="data">
            <b-badge :variant="getBadge(data.item.status)">{{getObjectStatus(data.item.status)}}</b-badge>
          </template>
          <template slot="action" slot-scope="data">
            <b-button-group size="sm">
                <b-button variant="outline-danger"
                v-if="data.item.status>-1"
                  @click="confirmDelete(data.item)">
                  <i class="fa fa-trash"></i> {{$t('common.button.delete')}}
                </b-button>
                <b-button variant="outline-success" v-else
                  @click="recoverObj(data.item)">
                  <i class="fa fa-recycle"></i> {{$t('common.button.recover')}}
                </b-button>
              </b-button-group>
          </template>
        </b-table>
        <template slot="footer">
          <b-row class="pull-right">
            <b-col>
              <nav>
                <pager :frame="8"
                        :pageCount="page_count"
                        :page="page"
                        @change="change_page">
                  </pager>
              </nav>
            </b-col>
          </b-row>
        </template>
      </b-card>
      </transition>
    </b-col>
    <b-modal id="viewModal" title="Thông báo" header-bg-variant="info" no-body ok-only>
      <div class="h3">{{$t('common.image_error')}}</div>
    </b-modal>
  </b-row>
</template>
<style scoped>
  label{
    margin-bottom: 0rem;
  }
</style>
<script>
  import { validationMixin } from 'vuelidate'
  import { required, minLength,maxLength,numeric,requiredIf } from 'vuelidate/lib/validators'
  import { mapGetters, mapActions } from 'vuex'
  import moment from 'moment'
  import { BasicSelect } from 'vue-search-select'
  import Pager from '../Pager'
  import '../../../node_modules/vue-toastr-2/dist/vue-toastr-2.min.css'

  import {Role} from '../../helpers/role'
  import { Business } from '../../helpers/business'
  import { uuid } from 'vue-uuid'

  export default {
    mixins: [validationMixin],
    components: {BasicSelect,Pager},
    props: {
      hover: {
        type: Boolean,
        default: true
      }
    },
    data: function () {
      return {
        processing:false,
        branch:{
          id:null,
          tongnhap:0,tongban:0,
          logo:null,
          logo_url:null,
          name:null,
          des:null,
          code:moment().valueOf().toString(),
          company_id:{},
          address:null,
          phone:null,
          user_create_id:null,
          user_update_id:null,
          chieu_tu:'13:00',
          sang_den:'12:00',
          sang_tu:'09:00',
          chieu_den:'18:00'
        }
      }
    },

    validations: {
      branch: {
        name: {
          required,
          maxLength:maxLength(50),
          async isUnique(value) {
            if (value==null) return true
            if(this.branch && this.branch.id){
              return true
            }

            const res = await this.checkNameUnique({name:value,company_id:this.user_login.company_id})
            return Boolean(res.data)
          }
        },
        code: {
          required,
          maxLength:maxLength(25),
          async isUnique(value) {
            if (value==null) return true
            if(this.branch && this.branch.id){
                return true
            }

            const res = await this.checkCodeUnique({code:value,company_id:this.user_login.company_id})
            return Boolean(res.data)
          }
        },
        company_id:{
          required: requiredIf(function () {
            return this.is_super
          })
        },
        phone: {
          required,
          minLength: minLength(10),
          numeric,
          async isUnique(value) {
            if (value==null) return true
            if(this.branch && this.branch.id){
              return true
            }

            const res = await this.checkPhoneUnique({phone:value,company_id:this.user_login.company_id})
            return Boolean(res.data)
          }
        }
      }
    },
    mounted() {
      let vm=this
      vm.$refs.txt_name.$el.focus()
      vm.get_ls_branch()
      vm.get_ls_company_ddl(vm.user_login.company_id)
    },
    computed: {
      ...mapGetters('st_branch', ['ls_branch','filter','page','per_page','page_count']),
      fields(){
        let fields=[]

        let user=this.$store.state.st_authentication.user
        if(user.role==Role.Super_Admin){
          fields.push({key: 'company',label:this.$t('common.company'),sortable: true, variant:'info'})
          fields.push({key: 'business',label:this.$t('common.business'),sortable: true})
        }

        if(user.role==Role.Admin){
          fields.push({key: 'branch',label:this.$t('common.company_branch'),sortable: true, variant:'info'})
        }

        fields.push({key: 'name',label:this.$t('common.fullname'),sortable: true, variant:'primary'})
        fields.push({key: 'address',label:this.$t('common.email'),sortable: true})
        fields.push({key: 'phone',label:this.$t('common.phone'),sortable: true})
        fields.push({key: 'created_at',label:this.$t('common.created_at'),sortable: true})
        fields.push({key: 'status',label:this.$t('common.status'),sortable: true})

        if(user.role==Role.Super_Admin||user.role==Role.Admin||user.role==Role.Branch_Admin){
          fields.push({key: 'action',label:'',sortable: false})
        }

        return fields
      },
      filter:{
        get: function(){
          return this.$store.state.st_branch.filter
        },
        set: function(text){
          this.$store.commit('st_branch/set_filter',text)
        }
      },
      business() {
        let user= this.$store.state.st_authentication.user
        return user.company.business
      },
      is_admin() {
        let user= this.$store.state.st_authentication.user
        return user&&user.role==Role.Admin
      },
      is_super() {
        let user= this.$store.state.st_authentication.user
        return user&&user.role==Role.Super_Admin
      },
      user_login() {
        return this.$store.state.st_authentication.user
      },
      ls_company_ddl(){
        debugger
        let company= this.$store.state.st_company.ls_company_ddl
        debugger
        return company
      },
      filteredAndSortedData:{
        get: function(){
          let vm=this
          let result = vm.$store.state.st_branch.ls_branch
          if (vm.filter) {
              result = result.filter(item =>vm.searchLike(item))
          }

          return result
        }
      }
    },
    methods: {
      ...mapActions('st_branch', ['get_ls_branch', 'apply_filter','change_page']),
      ...mapActions('st_company', ['get_ls_company_ddl']),
      async checkPhoneUnique(param){
        return await this.$store.dispatch(`st_branch/phone_unique`,param)
      },

      rowDeleted(item, type){
        debugger
        if (!item || type !== 'row') return
        debugger
        if (item.status === -1) return 'table-danger'
      },

      async checkCodeUnique(param){
        return await this.$store.dispatch(`st_branch/code_unique`,param)
      },

      async checkNameUnique(param){
        return await this.$store.dispatch(`st_branch/name_unique`,param)
      },

      async submit(){
        const isValid = await this.isValid()
        if (!isValid) {
            return
        }

        this.saveOrUpdateBranch()
      },
      _validations () {
        return new Promise(resolve => {
          if (this.$v.$error || !this.$v.$pending) {
              return resolve()
          }
          let poll = setInterval(() => {
          if (!this.$v.$pending) {
              clearInterval(poll)
              resolve()
          }
          }, 200)
        })
      },

      onSelect(item){
        debugger
        this.branch.company_id=item
      },
      getRowCount (items) {
        return items.length
      },
      rowClicked (item) {
        this.branch=item
      },

      async isValid () {
          this.$v.$reset()
          this.$v.$touch()
          await this._validations()
          return Promise.resolve(!this.$v.$error)
      },
      saveOrUpdateBranch() {
        var app = this
        if (app.processing === true) {
          return
        }

        app.processing = true
        if(app.branch.id){
          app.branch.user_update_id=app.user_login.id
          app.$store.dispatch('st_branch/update',app.branch)
          .then(function (resp) {
            app.get_ls_branch()
            app.clear()
            app.processing = false
            app.$toastr.success(app.$t("common.updated_success"),app.$t("common.message"))
          })
          .catch(function (resp) {
            app.processing = false
            app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          })
        }else{
          app.branch.user_create_id=app.user_login.id
          if(!app.is_super){
            app.branch.company_id=app.user_login.company_id
          }
          app.$store.dispatch('st_branch/create',app.branch)
          .then(function (resp) {
            app.get_ls_branch()
            app.clear()
            app.processing = false
            app.$toastr.success(app.$t("common.created_success"),app.$t("common.message"))
          })
          .catch(function (resp) {
            app.processing = false
            app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          })
        }
      },
      async recoverObj(obj){
        let app=this
        if(obj.status>=0) return
        let co=await this.$store.dispatch('st_branch/recover_obj',obj.id)
        if(co&&co.data.ok){
          app.get_ls_branch()
          app.$toastr.success(app.$t("common.recovered_success"),app.$t("common.message"))
        }else{
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        }
      },
      confirmDelete(obj) {
        this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
        .then(value => {
          if(value!=true) return
          this.deleteObj(obj)
        })
        .catch(err => {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        })
      },
      async deleteObj(obj) {
        let app=this
        let co=await app.$store.dispatch('st_branch/delete_obj',obj.id)
        if(co&&co.data){
          if(co.data.status==-1){
            app.$toastr.error(app.$t('common.object_in_use',{obj:app.branch.name}),app.$t("common.message"))
            return
          }
          if(co.data.status==0){
            app.$toastr.error(app.$t('common.object_in_use',{obj:app.branch.name}),app.$t("common.message"))
            return
          }

          app.get_ls_branch()
          app.$toastr.success(app.$t("common.deleted_success"),app.$t("common.message"))
        }else{
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        }
      },
      clear() {
        this.branch={id:null,name:null,logo:null,logo_url:null,company_id:null,code:moment().valueOf().toString(),
        chieu_tu:'13:00',address:null,user_create_id:null,user_update_id:null,
        sang_den:'12:00',phone:null,
        tongnhap:0,tongban:0,des:null,
        sang_tu:'09:00',
        chieu_den:'18:00'}
        this.$refs.txt_name.$el.focus()
        this.$v.$reset()
      },

      searchLike:function(item){
        return item.name.includes(this.filter)
        ||item.code.includes(this.filter)
        ||item.address.includes(this.filter)
        ||item.phone.includes(this.filter)
        ||item.company.name.toString().includes(this.filter)
      },
      getImage:function(e){
        let app=this
        let image=e.target.files[0]
        if(image.size>102400){
          app.$bvModal.show("viewModal")
          document.getElementById("fileControl").value = ""
          return
        }
        let reader=new FileReader()
        reader.readAsDataURL(image)
        reader.onload=e=>{
          app.branch.logo=e.target.result
        }
      }
  }
}
</script>
